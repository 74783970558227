.total_incidencias{
	background-color: #ededed !important;
}

.faltas{
	background-color: #ffe5c4 !important;
}

.retardos{
	background-color: #fff8d8 !important;
}