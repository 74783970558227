.ROLE_ADMIN{
	background: #daa520 !important;
	color: white;
}
.EJECUTIVO_PRIMARIO{
	background: #e4b849 !important;
	color: white;
}
.EJECUTIVO_SECUNDARIO{
	background: #e4ab49 !important;
	color: white;
}








.MOVIMIENTOS_NOMINA{
	background: #ff5314 !important;
	color: white;
}

.CONSTANCIAS{
	background: #ff818d !important;
	color: white;
}

.INCIDENCIAS{
	background: #76daff !important;
	color: white;
}

.CONTRATOS{
	background: #ffa500 !important;
	color: white;
}

.ARCHIVOS{
	background: #ff9af0 !important;
	color: white;
}










.DEPTO_NOMINA{
	background: #00c498 !important;
	color: white;
}

.RECEPCION{
	background: #ececec !important;
}
.USUARIO_OFICIOS{
	background: #cc9aff !important;
	color: white;
}






.CONTROL{
	background: #a89aff !important;
	color: white;
}




/*







RECEPCION

*/