$theme: "journal" !default;

//
// Color system
//


@import './colors.scss';



$min-contrast-ratio:   1.28 !default;

// Fonts

// $headings-font-family:   "News Cycle", "Arial Narrow Bold", sans-serif !default;
// $headings-font-weight:    700 !default;
// $headings-line-height:    1.1 !default;

// Buttons

$input-btn-padding-x:         1rem !default;

// Navbar

$navbar-light-color:                rgba($black, .7) !default;
$navbar-light-hover-color:          $black !default;
$navbar-light-active-color:         $black !default;

$navbar-brand-font-size:            10;

// Pagination

$pagination-hover-color:            $white !default;
$pagination-hover-bg:               $primary !default;
$pagination-hover-border-color:     $primary !default;


@import '~bootstrap/scss/bootstrap.scss';
@import "~fontawesome-4.7/scss/font-awesome.scss";








// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 100;
//   font-display: swap;
//   src: url(./fonts/KFOkCnqEu92Fr1MmgVxIIzIXKMny.woff2) format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./fonts/KFOlCnqEu92Fr1MmWUlfBBc4AMP6lQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}




h1,h2,h3,h4,h5,h6,b{
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

a{
  // font-family: 'Suez One', serif;
}

p, body, html, small, a{
  font-family: 'Roboto', sans-serif !important;
}

small{
  // font-family: 'DM Serif Text';
}










.card{
  margin-top: 0 !important;
}





.obligatorios {
    color: #c81212;
    font-size: 10px;
    vertical-align: top;
}






// Navbar

.bg-dark {
  background-color: $black !important;
}

.bg-light {
  background-color: $white !important;
  color: $black;
  border: 1px solid $gray-200;

  &.navbar-fixed-top {
    border-width: 0 0 1px;
  }

  &.navbar-fixed-bottom {
    border-width: 1px 0 0;
  }
}





.navbar {
    // flex-wrap: wrap !important;
    padding: 0px 10px !important;
    justify-content: space-between !important;
    margin-bottom:25px;
}

label {
  display: block;
  // margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}


.nm{
  margin:0 !important;
}


.v{
  vertical-align: middle;
}


.vat{
  vertical-align: top;
}

.pointer{
  cursor:pointer;
}



.loader { 
  width:100%; 
  margin:0 auto;
  border-radius:0px;
  border:4px solid transparent;
  position:relative;
  padding:1px;
}
.loader:before {
  content:'';
  border:1px solid #e0e0e0; 
  border-radius:0px;
  position:absolute;
  top:-4px; 
  right:-4px; 
  bottom:-4px; 
  left:-4px;
}
.loader .loaderBar { 
  position:absolute;
  border-radius:0px;
  top:0;
  right:100%;
  bottom:0;
  left:0;
  background:#e0e0e0; 
  width:0;
  animation:borealisBar 2s linear infinite;
}

@keyframes borealisBar {
  0% {
    left:0%;
    right:100%;
    width:0%;
  }
  10% {
    left:0%;
    right:75%;
    width:25%;
  }
  90% {
    right:0%;
    left:75%;
    width:25%;
  }
  100% {
    left:100%;
    right:0%;
    width:0%;
  }
}


.text-left{
  text-align:left;
}

.text-right{
  text-align:right;
}

.text-center{
  text-align:center;
}




.card{
  padding:0 !important;
}


.bg-facebook{
  background: #2374e1 !important;
}


.bg-instagram{
  background: #d9307f !important;
}



.bg-twitter{
  background: #1ca1f2 !important;
}

.bg-youtube{
  background: #ff0000 !important;
}



hr.style-six {
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}




.form-label{
  font-weight: bold;
}

@media screen and (max-width:768px) {

   .navbar{
    margin-bottom: 0;
  }

  .padding_mobil{
    padding: 15px 15px 0 15px;
  }

}


@media screen and (min-width:768px) {

  .onlymobile{
    display: none;
  }

}













.estatus_1 {
  background: blue;
}

.estatus_2 {
  background: yellow !important;
  color: black;
}

.estatus_3 {
  background: grey !important;
  color: black;
}

.estatus_4 {
  background: orange !important;
  color: black;
}