.calendar-container {
  font-family: Arial, sans-serif;
}

.calendar {
  display: flex;
  flex-wrap: wrap;
}

.calendar-months {
  flex: 1 0 50%;
  display: flex;
}

.calendar-month {
  flex: 1;
  padding: 10px;
}

.month-header {
  font-weight: bold;
  margin-bottom: 5px;
}

.days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.day {
  text-align: center;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
}

.selected {
  background-color: orange;
}
