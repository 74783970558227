
.fcc-other-month {
  visibility: hidden !important;
}
.head-wall {

  background: #000000;
  color: white;
  padding: 4px;
  border-bottom: 3px solid white;
}
.month-name {
  margin: 2px;
  color: white;
}
.month-name-col {
  background-color: #ff9800;
}

.calender-date {
  color: white;
  font-weight: 700;
}
/* th {
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 8px;
  font-size: 15px;

} */
.tableContainer table {
  // float: left;
  margin: 5px auto;
  // width: 40%;
  // background-color: rgb(248, 250, 216);

  // margin: 10px;
  border: 2px solid #ff9800;
  // padding: 10px;
  border-radius: 10px;
  // height: 400px !important;
}

.calender-row {
  padding: 10px;
  color: rgb(0, 0, 0);
}

.calender-col {
  padding: 5px;
  color: rgb(0, 0, 0);
  width: 20px;
  font-weight: 500;
  text-align: center;
}

.isDates-grey {
  color: #b2b2b2;
}


.no_seleccionable {
    background: #8a8989;
    cursor: not-allowed;
}
.no_seleccionable_picado {
    background: red;
}