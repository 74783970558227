$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #eee !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #aaa !default;
$gray-600: #777 !default;
$gray-700: #495057 !default;
$gray-800: #333 !default;
$gray-900: #222 !default;
$black:    #000 !default;

$blue:    #eb6864 !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #f57a00 !default;
$orange:  #fd7e14 !default;
$yellow:  #f5e625 !default;
$green:   #22b24c !default;
$teal:    #20c997 !default;
$cyan:    #369 !default;

$primary:       #f5780d !default;
$secondary:     #c76008 !default;
$success:       #46aa6e !default;
$info:          $cyan !default;
$warning:       #ad9100 !default;
$danger:        #eb001f !default;
$light:         #f4f5f8 !default;
$dark:          #000000 !default;


